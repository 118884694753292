import {Component, ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import {MdbRippleModule} from '@elit-it/mdb5/ripple';
import {MdbCollapseDirective, MdbCollapseModule} from '@elit-it/mdb5/collapse';
import {RouterLink, RouterLinkActive} from '@angular/router';
import {MatRipple, MatRippleModule} from '@angular/material/core';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [CommonModule, MdbRippleModule, MdbCollapseModule, RouterLink, RouterLinkActive, MatRippleModule],
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @ViewChild(MdbCollapseDirective) navbar: MdbCollapseDirective;
  @ViewChild(MatRipple) ripple: MatRipple;

  closeNavbar() {
    this.navbar.hide();
    if (!this.navbar.collapsed) this.ripple.launch({ centered: true })
  }

}
