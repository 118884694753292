import {Injectable} from '@angular/core';
import {MdbNotificationConfig, MdbNotificationService} from '@elit-it/mdb5/notification';
import {AlertComponent} from './alert.component';
import {AlertType} from './alert.interface';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  private defaultConfig: MdbNotificationConfig = {
    position: 'top-right',
    delay: 7500,
    autohide: true,
    stacking: true,
  }

  constructor(
    private notificationService: MdbNotificationService,
  ) {
  }

  info(title?: string, message?: string, config?: MdbNotificationConfig): void {
    const type: AlertType = 'toast-info';
    this.notificationService.open(AlertComponent, {
      ...this.defaultConfig,
      ...config,
      data: {
        type: type, icon: 'fa-solid fa-circle-info', time: null,
        title: title || 'title', message: message || 'message'
      },
    });
  }

  success(title?: string, message?: string, config?: MdbNotificationConfig): void {
    const type: AlertType = 'toast-success';
    this.notificationService.open(AlertComponent, {
      ...this.defaultConfig,
      ...config,
      data: {
        type: type, icon: 'fa-solid fa-circle-check', time: null,
        title: title || 'Sikeres művelet', message: message || 'A kiválasztott művelet hibamentesen vévigment rendszerünkben!'
      },
    });
  }

  warning(title?: string, message?: string, config?: MdbNotificationConfig): void {
    const type: AlertType = 'toast-warning';
    this.notificationService.open(AlertComponent, {
      ...this.defaultConfig,
      ...config,
      data: {
        type: type, icon: 'fa-solid fa-circle-exclamation', time: null,
        title: title || 'Figyelmeztetés', message: message || 'Ez egy alapértelmzett üzenet! Kérjük a rendszer használata során legyen nagyon körültekintő!'
      },
    });
  }

  error(title?: string, message?: string, config?: MdbNotificationConfig): void {
    const type: AlertType = 'toast-danger';
    this.notificationService.open(AlertComponent, {
      ...this.defaultConfig,
      ...config,
      data: {
        type: type, icon: 'fa-solid fa-circle-exclamation', time: null,
        title: title || 'Sikertelen művelet', message: message || 'A kiválasztott művelet során valamilyen hiba került a rendszerbe! Kérjük kis idő elteltével próbálja meg újra.'
      },
    });
  }

}
