import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

const routes: Routes = [
  {path: '', loadChildren: () => import('./pages/home-page/home.module').then(m => m.HomeModule)},
  {path: 'kapcsolat', loadChildren: () => import('./pages/contact-page/contact.module').then(m => m.ContactModule)},
  {path: 'csarnok', loadChildren: () => import('./pages/hall-page/hall.module').then(m => m.HallModule)},
  {path: 'szolgaltatasok', loadChildren: () => import('./pages/services-page/services.module').then(m => m.ServicesModule)},
  {path: 'terembeosztas', loadChildren: () => import('./pages/calendar-page/calendar.module').then(m => m.CalendarModule)},
  {path: 'terembeosztas/igenyfelmeres', loadChildren: () => import('./pages/hall-reservation-page/hall-reservation.module').then(m => m.HallReservationModule)},
  {path: 'esemenyek', loadChildren: () => import('./pages/events-page/events.module').then(m => m.EventsModule)},
  {path: 'hirek', loadChildren: () => import('./pages/news-page/news.module').then(m => m.NewsModule)},
  {path: 'nyaritabor', loadChildren: () => import('./pages/summer-camp-page/summer-camp.module').then(m => m.SummerCampModule)},
  {path: 'nyaritabor/jelentkezes', loadChildren: () => import('./pages/summer-camp-join-page/summer-camp-join.module').then(m => m.SummerCampJoinModule)},
  {path: 'eredmenyek', loadChildren: () => import('./pages/results-page/results.module').then(m => m.ResultsModule)},

  {path: 'impresszum', loadChildren: () => import('./pages/imprint-page/imprint.module').then(m => m.ImprintModule)},
  {path: 'hazirend', loadChildren: () => import('./pages/house-policy-page/house-policy.module').then(m => m.HousePolicyModule)},
  {path: 'adatvedelmi-nyilatkozat', loadChildren: () => import('./pages/privacy-statement-page/privacy-statement.module').then(m => m.PrivacyStatementModule)},
  {path: 'adatvedelmi-nyilatkozat-hirlevel', loadChildren: () => import('./pages/privacy-statement-newsletter-page/privacy-statement-newsletter.module').then(m => m.PrivacyStatementNewsletterModule)},
  {path: 'cookies-nyilatkozat', loadChildren: () => import('./pages/cookies-statement-page/cookies-statement.module').then(m => m.CookiesStatementModule)},
  {path: 'panaszkezelesi-szabalyzat', loadChildren: () => import('./pages/complaint-policy-page/complaint-policy.module').then(m => m.ComplaintPolicyModule)},

  // Sablon
  // {path: '', loadChildren: () => import('./pages/').then(m => m)},

  {path: '**', loadChildren: () => import('./pages/_error-page/error.module').then(m => m.ErrorModule)},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
