import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AlertComponent} from './alert.component';
import {AlertService} from './alert.service';
import {MdbNotificationModule} from '@elit-it/mdb5/notification';


@NgModule({
  declarations: [
    AlertComponent,
  ],
  imports: [
    CommonModule,
    MdbNotificationModule,
  ],
  providers: [
    AlertService,
  ]
})
export class AlertModule {
}
