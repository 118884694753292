import {Component} from '@angular/core';
import {MdbNotificationRef} from '@elit-it/mdb5/notification';
import {AlertType} from './alert.interface';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
export class AlertComponent {
  public type: AlertType;
  public icon?: string | null;
  public time?: string | null;
  public title: string;
  public message: string;

  constructor(
    private notificationRef: MdbNotificationRef<AlertComponent>,
  ) {
  }

  close() {
    this.notificationRef.close();
  }

}
