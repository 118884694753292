import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavbarComponent} from './@shared/components/navbar/navbar.component';
import {FooterComponent} from './@shared/components/footer/footer.component';
import {HttpClientModule} from '@angular/common/http';
import {AlertModule} from "./@shared/components/alert/alert.module";
import {environment} from "../environments/environment";
// import {initializeApp, provideFirebaseApp} from "@angular/fire/app";
// import {getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService} from "@angular/fire/analytics";
// import {getPerformance, providePerformance} from "@angular/fire/performance";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,

    NavbarComponent,
    FooterComponent,
    AlertModule,

    // provideFirebaseApp(() => initializeApp(environment.firebase)),
    // provideAnalytics(() => getAnalytics()),
    // providePerformance(() => getPerformance()),
  ],
  providers: [
    // ScreenTrackingService, UserTrackingService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
