import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <div style="min-height: 100vh; display: flex; flex-direction: column;">
      <app-navbar></app-navbar>
      <main style="flex: 1">
        <router-outlet></router-outlet>
      </main>
      <app-footer></app-footer>
    </div>
  `
})
export class AppComponent {
}
